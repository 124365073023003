import { Box, Typography, List, ListItem } from "@mui/material";
import { Stack } from "@mui/system";
import { PresaleLocations } from "../../components/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faGlobe } from "@fortawesome/free-solid-svg-icons";
import PageTitle from "../../components/PageTitle/PageTitle";
import PageBase from "../../components/PageBase/PageBase";
import Link from "@mui/material/Link";
import TextLink from "../../components/TextLink/TextLink";

const Tickets = () => {
  return (
    <PageBase>
        <Stack textAlign={"center"}>
          <PageTitle title="TICKETS" />
          <Box
            sx={{ width: { xs: "90vw", md: "inherit" }, textAlign: "justify" }}
          >
            <Typography variant="subtitle2" textAlign={"center"}>
              Discounted presale tickets are now on sale{" "}
              { <TextLink url="https://fienta.com/et/kanamajfest-v" linkText="online" />
              }{" "}
              and at the following locations for CASH!
            </Typography>
            <Typography
              variant="h6"
              marginTop={1}
              fontWeight={"bold"}
              textAlign={"center"}
            >
              Presale €17. At the door €23.
            </Typography>
            <hr />
          </Box>
          <Box
            sx={{ backgroundColor: "inherit" }}
            textAlign={"justify"}
            marginTop={2}
          >
            <Typography variant="subtitle2">
              Physical tickets are now available at the following locations for
              CASH:
            </Typography>
            <Typography
              variant="h6"
              fontWeight={"bold"}
              marginTop={2}
              marginBottom={2}
            >
              {PresaleLocations.map((loc) => {
                return loc.name;
              }).join(" // ")}
            </Typography>
            <hr />
            <Typography sx={{ marginTop: "10px" }}>
              All tickets are beautifully designed to keep as a memory and come
              with a free sticker!
            </Typography>
            <Typography>
              {
                <Typography fontWeight={"bold"} display={"inline-block"}>
                  Support your local&nbsp;
                </Typography>
              }
              and go buy a record, go eat something or have a drink and grab
              your ticket at the same time!
            </Typography>
            <Typography>
              Again,{" "}
              {
                <Typography display={"inline-block"} fontWeight={"bold"}>
                  CASH ONLY&nbsp;
                </Typography>
              }{" "}
              for the tickets!
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                srcSet="/img/ticket_300.jpg 300w, /img/ticket_512.jpg 512w, /img/ticket_768.jpg 768w"
                sizes="(max-width: 600px) 300px, (max-width: 1024px) 512px, 768px"
                src="/img/ticket_768.jpg"
                alt="ticket"
              />
            </Box>
            <List sx={{ display: "inline-block", textAlign: "center" }}>
              {PresaleLocations.map((loc) => (
                <ListItem>
                  <Link
                    href={loc.web}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ color: "var(--kanamaja-red)" }}
                  >
                    <Box sx={{ display: { xs: "none", md: "contents" } }}>
                      <FontAwesomeIcon icon={faGlobe} />
                      &nbsp;
                    </Box>
                    <Typography display={"contents"}>{loc.name}</Typography>
                  </Link>
                  <Typography>&nbsp;//&nbsp;</Typography>
                  <Link
                    href={`https://www.google.com/maps/search/?api=1&query=${loc.name}`}
                    target="_blank"
                    rel="noreferrer"
                    sx={{ color: "var(--kanamaja-red)" }}
                  >
                    <Box sx={{ display: { xs: "none", md: "contents" } }}>
                      <FontAwesomeIcon icon={faLocationDot} />
                      &nbsp;
                    </Box>
                    <Typography display={"contents"}>{loc.address}</Typography>
                  </Link>
                </ListItem>
              ))}
            </List>
            <Box marginTop={2} marginBottom={4}>
              <Typography variant="h6">
                Online tickets available through Fienta.
              </Typography>
              <TextLink url="https://fienta.com/et/kanamajfest-v" icon={faGlobe} linkText="&nbsp;Buy here!"/>
            </Box>
          </Box>
        </Stack>
    </PageBase>
  );
};

export default Tickets;
