import "./LandingPage.css";
import { Stack, Typography, Box, Container } from "@mui/material";
import ModalImage from "react-modal-image";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";
import TextLink from "../../components/TextLink/TextLink";
import PageContent from "../../components/PageContent/PageContent";

const LandingPage = () => {
  return (
    <div className="LandingPage">
      <Stack>
        <PageTitle sxProps={{ marginTop: 3, fontFamily: 'RetroTeam', color: 'var(--kanamaja-washed-white)' }} title="Kanamaja Fest 5" />
        <Typography marginTop={-1} variant="button">
          15th June 2024
        </Typography>
        <Box marginTop={4} fontSize={"1.2rem"}>
          <Typography variant="subtitle2" fontSize={"inherit"}>
            Music, art exhibitions, skating, bar and food. Celebrating life,
            friendship and summer.
          </Typography>
        </Box>
        <Typography marginTop={4} marginBottom={4} fontWeight={"bold"}>
          Presale tickets are out!{" "}
          {
            <TextLink
              sxTypo={{ fontWeight: "bold" }}
              openInSameTab={true}
              linkText="Grab yours now!"
              url="/tickets"
            />
          }
        </Typography>
      </Stack>
      <PageBase>
        <Stack>
          <PageContent>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }} p={2}>
              <ModalImage
                hideDownload={true}
                hideZoom={false}
                small="/img/kanamaja_poster_512.jpg"
                medium="/img/kanamaja_poster_768.jpg"
                alt="poster"
              />
              <Box marginLeft={2}>
                <Stack width={"100%"}>
                  <Typography variant="subtitle1" textAlign={"left"}>
                    Kanamaja Fest is back this year on{" "}
                    <a
                      href="https://www.facebook.com/events/1307929883931930/"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      SATURDAY JUNE 15th!
                    </a>
                  </Typography>
                  <Typography variant="subtitle1" textAlign={"justify"}>
                    Same location as last year - the Krulli Skate Park on Volta
                    street, Tallinn. Doors at 2pm, bands kick off at 3pm.
                  </Typography>
                  <Typography marginTop={2} variant="subtitle1" textAlign={"justify"}>
                    The lineup is ready and we have more artists this year than
                    ever before.
                  </Typography>
                  <Typography variant="subtitle1" textAlign={"justify"}>
                    We will have food, drinks and small craft market on the spot
                    to keep you hydrated, fed and entertained throughout the
                    day.
                  </Typography>
                  <Typography marginTop={2} variant="subtitle1" textAlign={"justify"}>
                    This year, we are also excited to announce a special
                    partnership with the brand new brewery, just freshly opened
                    in Pohjala Tehas:{" "}
                    {
                      <TextLink
                        linkText="Tuletorn"
                        url="https://maps.app.goo.gl/Y49FgUtpQaPd3ER7A"
                      />
                    }
                    . They will be providing us with a special brew, just for Kanamaja.
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </PageContent>
        </Stack>
      </PageBase>
    </div>
  );
};

export default LandingPage;
