import { Box, Stack, Typography } from "@mui/material";
import ModalImage from "react-modal-image";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";
import PageContent from "../../components/PageContent/PageContent";

const History = () => {
  return (
    <PageBase>
      <Stack textAlign={"center"}>
        <PageTitle title="HISTORY" />
        <PageContent>
          <Box
            sx={{
              textAlign: { xs: "justify", md: "inherit" },
              marginBottom: 3,
            }}
          >
            <Typography variant="subtitle2">
              A big shout out to all the bands, artists, DJ’s and volunteers
              that were part of Kanamaja Fest over the last 5 years.
            </Typography>
            <Typography variant="subtitle2">
              Thanks to you folks we built up a small festival that started in
              2019 in our yard at the now legendary Köie 4 house.
            </Typography>
          </Box>
          <hr />
          <Stack
            textAlign={"center"}
            alignItems={"center"}
            marginTop={2}
            marginBottom={2}
            direction={"row"}
            spacing={2}
            display={"inline-flex"}
          >
            <ModalImage
              hideDownload={true}
              small="/img/2021_thumbnail.jpg"
              large="/img/2021.jpg"
              alt="2021"
            />
            <ModalImage
              hideDownload={true}
              small="/img/2022_thumbnail.jpg"
              large="/img/2022.jpg"
              alt="2022"
            />
            <ModalImage
              hideDownload={true}
              small="/img/2023_thumbnail.jpg"
              large="/img/2023.jpg"
              alt="2023"
            />
          </Stack>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} marginBottom={2} marginTop={1}>
            2023
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Bands:
              </Typography>
            }{" "}
            Akli (LIT), Di'Artist, We Bless This Mess (POR), TV Motel, One
            Hidden Frame (FIN), Borm Bubu.
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                DJ's:
              </Typography>
            }{" "}
            Los Monster and friends.
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Exhibition:
              </Typography>
            }{" "}
            Curated by Carlos Lesmes: Sigrit Villido, Marceau Couve, Mari-Liis
            Kriisa, Ines Villido, Lee Kelomees and Carlos E. Lesmes.
          </Typography>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} marginBottom={2} marginTop={2}>
            2022
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Bands:
              </Typography>
            }{" "}
            Timuti8, Horror Dance Squad, Gentle Ropes (UA), GOK2, TV Motel.
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                DJ's:
              </Typography>
            }{" "}
            Luke and Madleen Teetsov-Faulkner.
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Exhibition:
              </Typography>
            }{" "}
            Canceled due to rain.
          </Typography>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} marginBottom={2} marginTop={2}>
            2021
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Bands:
              </Typography>
            }{" "}
            No More Fridays, Triptofan, Vera Vice, Timuti 8, Hobekuul, An
            Evening of Sad Music.
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                DJ's:
              </Typography>
            }{" "}
            Luke and Madleen Teetsov-Faulkner, Ali Moniri (IRN/UK).
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Exhibition:
              </Typography>
            }{" "}
            Solveig Saar, Andrii Mur, Ginta Bane, Sigrid Villido, Daniil Logovoi
            and Carlos Lesmes.
          </Typography>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} marginBottom={2} marginTop={2}>
            2020
          </Typography>
          <Typography textAlign={"justify"}>
            Cancelled due to Covid :(
          </Typography>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} marginBottom={2} marginTop={2}>
            2019
          </Typography>
          <Typography textAlign={"justify"}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Bands:
              </Typography>
            }{" "}
            Lonely Freja (as Hellflower), No More Fridays.
          </Typography>
          <Typography textAlign={"justify"} marginBottom={2}>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                DJ's:
              </Typography>
            }{" "}
            DJ Luke, Madleen Teetsov-Faulkner, DJ Uibs.
          </Typography>
        </PageContent>
      </Stack>
    </PageBase>
  );
};

export default History;
