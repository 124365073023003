import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Typography } from "@mui/material";

const MenuItem = (props: {
    icon: IconDefinition,
    name: string
}) => {
  return (
    <Button color="inherit">
      <FontAwesomeIcon icon={props.icon} />
      <Typography paddingLeft={1}>{props.name}</Typography>
    </Button>
  );
};

export default MenuItem;
