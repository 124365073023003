import { Grid } from "@mui/material";
import { Artists } from "../../components/artists";
import ArtistCard from "../../components/ArtistCard/ArtistCard";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";

const Lineup = () => {
  return (
    <PageBase maxWidthOverride="xl">
      <PageTitle title="LINEUP" />
        <Grid container spacing={{ xs: 2, md: 3 }}>
          {Artists.map((artist) => (
            <ArtistCard artistData={artist} />
          ))}
        </Grid>
    </PageBase>
  );
};

export default Lineup;
