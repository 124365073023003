import { Stack, Typography } from "@mui/material";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";
import PageContent from "../../components/PageContent/PageContent";
import TextLink from "../../components/TextLink/TextLink";

const AboutUs = () => {
  return (
    <PageBase>
      <Stack textAlign={"center"}>
        <PageTitle title="ABOUT US" />
        <PageContent sxProps={{ marginTop: "10px" }}>
          <Typography variant="h4" textAlign={"left"} gutterBottom>About the Festival</Typography>
          <Typography textAlign={"justify"} gutterBottom>
            Our goal is to{" "}
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                promote upcoming artists and bands
              </Typography>
            }{" "}
            and create a sense of community by organizing a one day music and
            art event that this year also includes a craft market and two
            stages. We are aiming to create a festival with varied music genres
            / art mediums where each person can find something for their
            particular taste.
          </Typography>
          <br />
          <Typography textAlign={"justify"} gutterBottom>
            We try to keep{" "}
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                our prices as low as possible
              </Typography>
            }{" "}
            in order to reach more people vs making more money. This is only
            possible by working with{" "}
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                volunteers
              </Typography>
            }{" "}
            that care about music and the arts. Our core team has been doing
            this for 6 years and several of our volunteers have stayed with us
            throughout.
          </Typography>
          <Typography
            marginTop={"10px"}
            fontWeight={"bold"}
            variant="subtitle1"
            gutterBottom
          >
            BIG SHOUT OUT TO ALL VOLUNTEERS HELPING OUT!
          </Typography>
          <Typography textAlign={"justify"} gutterBottom>
            We are an all inclusive event meaning all ages, backgrounds and
            nationalities are welcome!
          </Typography>
          <br/>
          <Typography variant="h4" textAlign={"left"} gutterBottom>Organization anno 2024</Typography>
          <Typography textAlign={"justify"} gutterBottom>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Dries Beerens:
              </Typography>
            }{" "}
            Belgian music promoter, who has lived in Estonia for 6 years, tightly involved in the underground music scene.
          </Typography>
          <Typography textAlign={"justify"} gutterBottom>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Carlos Lesmes:
              </Typography>
            }{" "}
            Award winning independent film maker from Colombia, living in Estonia for over 10 years and currently working on his PhD in Audiovisual Arts.
          </Typography>
          <Typography textAlign={"justify"} gutterBottom>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Henri Mall:
              </Typography>
            }{" "}
            Estonian musician and promoter working in the music scene for over 15 years.
          </Typography>
          <Typography textAlign={"justify"} gutterBottom>
            {
              <Typography fontWeight={"bold"} display={"inline-flex"}>
                Gerty Villo:
              </Typography>
            }{" "}
            Estonian graphic designer and artist who has worked with numerous bands and artists.
          </Typography>
          <br/>
          <Typography variant="h4" textAlign={"left"} gutterBottom>Contact</Typography>
          <div style={{textAlign: "left"}}>
          <TextLink openInSameTab={true}
                  linkText="kanamajafest@gmail.com"
                  url="mailto:kanamajafest@gmail.com"
                />
          </div>
        </PageContent>
      </Stack>
    </PageBase>
  );
};

export default AboutUs;
