import { SxProps, Theme } from '@mui/material/styles';
import { Box } from "@mui/material";

const PageContent = (props: {
    children: React.ReactNode;
    sxProps?: SxProps<Theme>;
  }) => {
  return (
    <Box sx={[
        { minWidth: { xs: "90vw", md: "inherit" } },
        ...(Array.isArray(props.sxProps) ? props.sxProps : [props.sxProps]),
    ]}>
        {props.children}
    </Box>
  );
};

export default PageContent;
