import {
  faCirclePlay,
  faLocationDot,
  faPalette,
  faTicket,
  faFilm,
  faPeopleGroup,
  faEnvelope
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { SocialMedia, SocialMediaClass, PresaleLocation } from "./types";

export const KanamajaFestTitle = "Kanamaja Fest";
export const KanamajaFestTitleShort = "Kanamaja";

export const MenuItems = [
  { name: "Lineup", icon: faCirclePlay, path: "lineup" },
  { name: "Exhibition", icon: faPalette, path: "art" },
  { name: "History", icon: faFilm, path: "history" },
  { name: "Tickets", icon: faTicket, path: "tickets" },
  { name: "Location", icon: faLocationDot, path: "location" },
  { name: "About us", icon: faPeopleGroup, path: "about" },
];

export const KanamajaSocialMedias: SocialMedia[] = [ 
  new SocialMedia("https://www.instagram.com/kanamaja.fest", SocialMediaClass.IG, faInstagram),
  new SocialMedia("https://www.facebook.com/KanamajaFest", SocialMediaClass.FB, faFacebook),
  new SocialMedia("mailto:kanamajafest@gmail.com", SocialMediaClass.MAIL, faEnvelope)
];

export const PresaleLocations: PresaleLocation[] = [
  new PresaleLocation("Burger Box", "Kopli 4a", "https://www.facebook.com/burgerboxbox/"),
  new PresaleLocation("Kivi Paber Käärid", "Telliskivi 60a-7", "https://kivipaber.ee/"),
  new PresaleLocation("Koht Bar", "Lai 8", "https://www.facebook.com/tubakas"),
  new PresaleLocation("Moritz Records", "Väike-Karja 5", "https://www.facebook.com/profile.php?id=61555467012916"),
  new PresaleLocation("Terminal Records&Bar", "Telliskivi 60/2", "https://www.terminal.ee/"),
  new PresaleLocation("Tuletorn Brewing", "Ankru 10", "https://www.instagram.com/tuletornbeer/")
];