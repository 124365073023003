import { Paper, SxProps, Typography } from "@mui/material";
import "./ArtistNameCountry.css";

const ArtistNameCountry = (props: {
  name: string | undefined;
  country: string | undefined;
  sx: SxProps | undefined
}) => {
  return (
    <Paper elevation={0} className="artist-name-country" sx={props.sx}>
      <Typography variant="h5" style={{ display: "inline-block" }} fontSize={"1.5rem"}>
        {props.name}&nbsp;
      </Typography>
      <Typography
        sx={{ mb: 1.5 }}
        style={{ display: "inline-block", margin: 0, verticalAlign: "top" }}
      >
        {props.country}
      </Typography>
    </Paper>
  );
};

export default ArtistNameCountry;
