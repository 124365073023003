import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./components/App/App";
import SocialFollow from "./components/Social/SocialFollow";
import { BrowserRouter } from "react-router-dom";
import { KanamajaSocialMedias } from "./components/Constants";
import reportWebVitals from "./reportWebVitals";
import FontStyles from "./fonts/fonts";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <div className="web">
      <header className="header">
      </header>
      <BrowserRouter>
      <FontStyles />
        <App />
      </BrowserRouter>
      <footer className="footer">
        <SocialFollow media={KanamajaSocialMedias} />
      </footer>
    </div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
