import { Grid } from "@mui/material";
import { Arts } from "../../components/arts";
import ArtistCard from "../../components/ArtistCard/ArtistCard";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";
import { Artist } from "../../components/artists";

const Exhibition = () => {
  const getArtistLasName = (fullName: string): string => {
    const names = fullName.trim().split(' ');
    return names.length > 0 ? names[names.length - 1] : fullName;
  };

  const sortArtists = () : Artist[] => {
    Arts.sort(function(a, b) {
      var lastNameA = getArtistLasName(a.name);
      var lastNameB = getArtistLasName(b.name);
      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    });

    return Arts;
  };

  return (
    <PageBase maxWidthOverride="xl">
      <PageTitle title="EXHIBITION" />
      <Grid container spacing={{ xs: 2, md: 3 }}>
        {sortArtists().map((art) => (
          <ArtistCard artistData={art} />
        ))}
      </Grid>
    </PageBase>
  );
};

export default Exhibition;
