import { createTheme } from "@mui/material";

export const KanamajaHeaderTheme = createTheme({
    palette: {
      primary: {
        main: '#0e5f66',
        contrastText: '#f8edd5',
      }
    },
});

export const KanamajaDrawerTheme = createTheme({
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          backgroundColor: '#0e5f66',
          color: '#f8edd5'
        }
      }
    }
  }
});