import { useParams, Navigate } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { Artists } from "../../components/artists";
import { Arts } from "../../components/arts";
import ArtistNameCountry from "../../components/ArtistNameCountry/ArtistNameCountry";
import "./ArtistDetails.css";
import SocialFollow from "../../components/Social/SocialFollow";
import {
  faFacebook,
  faInstagram,
  faSpotify,
  faYoutube,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons";
import { SocialMedia, SocialMediaClass } from "../../components/types";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import YoutubePlayer, {
  YoutubeOptions,
} from "../../components/YoutubePlayer/YoutubePlayer";
import PageBase from "../../components/PageBase/PageBase";
import PageContent from "../../components/PageContent/PageContent";
import { useState, useEffect } from "react";

const ArtistDetails = () => {
  const { artistId } = useParams();
  const [artistBio, setArtistBio] = useState<string>();
  const artist = Artists.concat(Arts).find((artist) => artist.id === artistId);

  async function loadBio() {
    if (artist !== undefined) {
      setArtistBio(await fetch(artist.bio).then((res) => res.text()));
    }
  }

  const generateArtistMedia = (): SocialMedia[] => {
    const media = [];
    if (artist?.ig !== undefined) {
      media.push(new SocialMedia(artist?.ig, SocialMediaClass.IG, faInstagram));
    }
    if (artist?.fb !== undefined) {
      media.push(new SocialMedia(artist?.fb, SocialMediaClass.FB, faFacebook));
    }
    if (artist?.yt !== undefined) {
      media.push(new SocialMedia(artist?.yt, SocialMediaClass.YT, faYoutube));
    }
    if (artist?.web !== undefined) {
      media.push(new SocialMedia(artist?.web, SocialMediaClass.WEB, faGlobe));
    }
    if (artist?.spotify !== undefined) {
      media.push(
        new SocialMedia(artist?.spotify, SocialMediaClass.SPOTIFY, faSpotify)
      );
    }
    if (artist?.soundcloud !== undefined) {
      media.push(
        new SocialMedia(
          artist?.soundcloud,
          SocialMediaClass.SOUNDCLOUD,
          faSoundcloud
        )
      );
    }
    return media;
  };

  useEffect(() => {
    loadBio();
  });

  return artist ? (
    <PageBase>
      <Stack textAlign={"justify"}>
        <PageContent>
          <Box>
            <picture>
              <source
                media="(max-width: 500px)"
                srcSet={`${artist.coverImg}_300.jpg`}
              />
              <source
                media="(max-width: 800px)"
                srcSet={`${artist.coverImg}_512.jpg`}
              />
              <source
                media="(min-width: 801px)"
                srcSet={`${artist.coverImg}_768.jpg`}
              />
              <img
                className="artist-details-img"
                src={`${artist.coverImg}_300.jpg`}
                alt={artist.name}
              />
            </picture>
          </Box>
          <Box>
            <ArtistNameCountry
              name={artist.name}
              country={artist.country}
              sx={{ height: "9vh", borderRadius: 0, marginTop: 0, padding: 0}}
            />
          </Box>
          {artist.style !== undefined ? (
            <Typography
              textAlign={"center"}
              fontSize={"1.2rem"}
              paddingTop={"5px"}
              paddingBottom={"5px"}
            >
              {artist.style.join(" / ")}
            </Typography>
          ) : undefined}
          <Box
            sx={{
              minHeight: "5vh",
              fontSize: "3vh",
              paddingTop: "1vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <SocialFollow media={generateArtistMedia()} />
          </Box>
          <Box sx={{ whiteSpace: "pre-line" }}>
            <Typography variant="body1" gutterBottom>
              {artistBio}
            </Typography>
          </Box>
          {artist.videoId !== undefined ? (
            <Box
              width={"100%"}
              sx={{
                display: "table",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <YoutubePlayer options={new YoutubeOptions(artist.videoId)} />
            </Box>
          ) : (
            ""
          )}
        </PageContent>
      </Stack>
    </PageBase>
  ) : (
    <Navigate to={"/"} />
  );
};

export default ArtistDetails;
