import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

export class SocialMedia {
  constructor(
    public link: string,
    public className: SocialMediaClass,
    public icon: IconDefinition
  ) {}
}

export class PresaleLocation {
  constructor(public name: string, public address: string, public web: string) {}
}

export enum SocialMediaClass {
  FB = "facebook",
  IG = "instagram",
  MAIL = "email",
  YT = "youtube",
  WEB = "web",
  SPOTIFY = "spotify",
  SOUNDCLOUD = "soundcloud",
}
