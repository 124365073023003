export const Artists: Artist[] = [
  {
    id: "A70F54C41BE141EC96941FB82E535935",
    name: "Petlia Pristrastija",
    country: "BY",
    coverImg: "/img/petlya",
    style: ["post-punk", "rock"],
    videoId: "fBidC6HKOK0",
    fb: "https://www.facebook.com/petlia",
    ig: "https://www.instagram.com/petliaband/",
    yt: "https://www.youtube.com/@user-qe6xy7vb5r",
    web: "https://petliatour.com",
    bio: "/data/petlya.txt"
  },
  {
    id: "EDA7FC740EE04BFC9E369CB28735F4A8",
    name: "Alum Alu",
    country: "LV",
    coverImg: "/img/alumalu",
    style: ["gypsy folk rock"],
    videoId: "3vW9-YxUbOM",
    fb: "https://www.facebook.com/alumalumusic/",
    ig: "https://www.instagram.com/alumalumalu/",
    yt: "https://www.youtube.com/channel/UC5-J-7OMSF2fAdBg8uoyyMA",
    spotify: "https://open.spotify.com/artist/6jl9FmZjHY5pD36jbw88TY",
    web: "https://failiem.lv/u/k2sumfagr",
    bio: "/data/alumalu.txt"
  },
  {
    id: "7CF822A4ACA64D96A985E1C1C6A1E9C8",
    name: "Kenors",
    country: "EE",
    coverImg: "/img/kenors",
    style: ["experimental funk ensemble"],
    videoId: "ob5mDb0Rqwo",
    ig: "https://www.instagram.com/kenorsband/",
    yt: "https://www.youtube.com/channel/UCe07aN2ZUIxg38Y4cE-9brQ",
    spotify: "https://open.spotify.com/artist/22OlyCYU8vn2tbmGtasWD5",
    bio: "/data/kenors.txt"
  },
  {
    id: "CEF27F04C8264943A8C776B14787A3BD",
    name: "GØK2",
    country: "EE",
    coverImg: "/img/gok2",
    style: ["alternative electro punk"],
    videoId: "_8PrYAv8rOY",
    fb: "https://www.facebook.com/iamgok2/",
    ig: "https://www.instagram.com/gok2music",
    web: "https://gok2official.com/",
    yt: "https://www.youtube.com/channel/UCpA8oViy62f5eyIhfc0zV3g",
    spotify: "https://open.spotify.com/artist/6hYdqGM0Dl0eVTSZB9LoV5",
    bio: "/data/gok2.txt"
  },
  {
    id: "E83886E850434BC8A6B6DDB1C52B7425",
    name: "Kaschalot",
    country: "EE",
    coverImg: "/img/kaschalot",
    style: ["progressive", "instrumental"],
    videoId: "z1GJzWgY4_s",
    ig: "https://www.instagram.com/kaschalotogram/?hl=en",
    fb: "https://www.facebook.com/kaschalotband",
    web: "http://kaschalot.bandcamp.com/",
    yt: "https://www.youtube.com/channel/UChwJjCJopEK9rlzQcTwlFkw",
    spotify: "https://open.spotify.com/artist/6CppWYu5Ou4cXnbM6jb2Wa",
    bio: "/data/kaschalot.txt"
  },
  {
    id: "57F855CB9FFE4E04BEC8C15453A3D903",
    name: "Dreamkrusher!",
    country: "EE",
    coverImg: "/img/dreamkrusher",
    style: ["alternative psychedelic rock"],
    fb: "https://www.facebook.com/dreamkrusher1",
    ig: "https://www.instagram.com/dreamkrusher_1/",
    web: "https://dreamkrusher1.bandcamp.com/",
    yt: "https://www.youtube.com/channel/UCwjSt2wOoxj_0SeFdTcnjpw",
    spotify: "https://open.spotify.com/artist/7djFWNozLtqLx8CSx6qC18",
    bio: "/data/dreamkrusher.txt"
  },
  {
    id: "856347CB777B4A47B6D605CCAB3AD090",
    name: "Zahir",
    country: "EE",
    coverImg: "/img/zahir",
    style: ["guitar trouble"],
    fb: "https://www.facebook.com/zahirface/",
    ig: "https://www.instagram.com/zahirtheband",
    web: "https://zahirtheband.bandcamp.com/album/seems-normal",
    bio: "/data/zahir.txt"
  },
  {
    id: "CCBC70576EBC4B22AFCBBA43DC63FA82",
    name: "Keista Bjauri žuvis",
    country: "LT",
    coverImg: "/img/fish",
    style: ["experimental", "avant-garde", "electrojazz"],
    videoId: "nh6A8thXlN0",
    fb: "https://www.facebook.com/KeistaBjauriZuvis/",
    web: "https://weirduglyfish.bandcamp.com/",
    ig: "https://www.instagram.com/weirduglyfish/",
    yt: "https://www.youtube.com/@weirduglyfish/",
    spotify: "https://open.spotify.com/artist/6Lq985fEe6pK1NfP8Khv7p",
    bio: "/data/fish.txt"
  },
  {
    id: "1C33BE460AE24431A9436711CA074887",
    name: "TRIPTOFAN",
    country: "EE",
    coverImg: "/img/triptofan",
    style: ["post surf-metal"],
    bio: "/data/triptofan.txt",
    ig: "https://www.instagram.com/_triptofan_",
    fb: "https://www.facebook.com/triptofannn",
    spotify: "https://open.spotify.com/artist/4bXXSs6I791K4XhEk1rrV7",
    soundcloud: "https://soundcloud.com/triptofann",
    web: "https://links.freshtunes.com/purkV",
    videoId: "xDgD3w1MVP4"
  },
  {
    id: "F51299B1D9D24484BE2CCC94E77400C9",
    name: "Hello Killu",
    country: "EE",
    coverImg: "/img/killu",
    style: ["avant-garde"],
    videoId: "1dqHi5upCG4",
    fb: "https://www.facebook.com/hellokillu/",
    ig: "https://www.instagram.com/hellokillutheband/",
    web: "https://hellokillu.bandcamp.com/album/making-your-unknown-known",
    yt: "https://www.youtube.com/hellokillu",
    soundcloud: "https://soundcloud.com/hellokillu",
    spotify: "https://open.spotify.com/artist/6phB344zsCPm6ZSsxEEH1o",
    bio: "/data/killu.txt"
  },
  {
    id: "4B60FDD77E7A40398D001B2DCA4EB802",
    name: "KÄSI",
    country: "EE",
    coverImg: "/img/kasi",
    style: ["alternative rock"],
    soundcloud: "https://soundcloud.com/ansambel-k2si",
    bio: "/data/kasi.txt"
  },
  {
    id: "4FB8E5BC54014D28BE23395E8A989057",
    name: "Sheik Anorak",
    country: "SWE",
    coverImg: "/img/sheik",
    style: ["minimal drumming trance"],
    videoId: "9rop6CnJG6w",
    fb: "https://www.facebook.com/sheikanorakmusic",
    ig: "https://www.instagram.com/sheik_anorak/",
    soundcloud: "https://soundcloud.com/sheik-anorak",
    web: "https://sheikanorak.bandcamp.com",
    yt: "https://www.youtube.com/channel/UCNtTnejDiwtIL4Yy8-T-uTQ",
    spotify: "https://open.spotify.com/artist/636HaQbAxsWjBxkh731cdH",
    bio: "/data/sheik.txt"
  },
];

export type Artist = {
  id: string;
  name: string;
  country?: string;
  coverImg: string;
  style?: string[];
  bio: string;
  videoId?: string;
  fb?: string;
  ig?: string;
  yt?: string;
  web?: string;
  spotify?: string;
  soundcloud?: string;
};
