import { Box, Stack, Typography } from "@mui/material";
import PageBase from "../../components/PageBase/PageBase";
import PageTitle from "../../components/PageTitle/PageTitle";
import PageContent from "../../components/PageContent/PageContent";
import TextLink from "../../components/TextLink/TextLink";
import "./Location.css";

const Location = () => {
  return (
    <PageBase>
      <Stack textAlign={"justify"}>
        <PageTitle title="LOCATION" />
        <Box>
          <iframe
            width="100%"
            height="450"
            loading="lazy"
            title="gmaps krulli"
            src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJCa--oaGTkkYRgzcspf0aTX8&key=AIzaSyC18xFT1u7OJDv8OEuP4ivSFeQTdpSxhtg"
          />
        </Box>
        <PageContent sxProps={{marginTop: 3}}>
        <Typography>
            For the second year in a row we’re returning to the Krulli SkatePark
            at{" "}
            {
              <TextLink
                url="https://www.krulli.co/"
                linkText="Krulli Kvartal"
              />
            }{" "}
            on Volta street.
          </Typography>        
          <Typography>
            The location can be easily reached by public transport, taxi, bikes,
            scooters, horses and is at a walking distance from Balti Jaam.
          </Typography>
          <Typography
            variant="h6"
            textAlign={"center"}
            marginTop={2}
            marginBottom={1}
          >
            Car & scooters
          </Typography>
          <Typography>
            If you are coming by car, keep in mind that it is not possible to
            park inside the Krulli area. There is a paid parking right next to
            the entrance of the Krulli Kvartal. Please respect the official city
            regulations. Same goes for the scooters, leave them in allowed areas
            regulated by your scooter provider.
          </Typography>
          <Typography
            variant="h6"
            textAlign={"center"}
            marginTop={2}
            marginBottom={1}
          >
            Public transport
          </Typography>
          <Box>
            <Typography>
              {
                <Typography fontWeight={"bold"} display={"inline-flex"}>
                  By Tramway -
                </Typography>
              }{" "}
              Lines 1, 2 and 5.{" "}
              {
                <TextLink
                  linkText="Volta stop"
                  url="https://transport.tallinn.ee/#plan//10402-1,10401-1/map/en"
                />
              }
            </Typography>
          </Box>
          <Box>
            <Typography>
              {
                <Typography fontWeight={"bold"} display={"inline-flex"}>
                  By Bus -
                </Typography>
              }{" "}
              Line 3.{" "}
              {
                <TextLink
                  linkText="Kungla stop"
                  url="https://transport.tallinn.ee/#plan//10303-1,10304-1/map/en"
                />
              }
            </Typography>
          </Box>
          <Box marginTop={4}>
            <TextLink url="https://www.krulli.co/" linkText="krulli.co" />
          </Box>
        </PageContent>
      </Stack>
    </PageBase>
  );
};

export default Location;
