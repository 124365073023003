import { Typography, Link, SxProps, Theme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";

interface TextLinkProps {
  url: string;
  linkText: string;
  openInSameTab?: boolean;
  icon?: IconDefinition;
  sxTypo?: SxProps<Theme>
}

const TextLink = (props: TextLinkProps) => {
  return (
    <Link
      href={props.url}
      target={props.openInSameTab ? "_self" : "_blank"}
      rel="noreferrer"
      sx={{ display: "ruby", color: "var(--kanamaja-red)" }}
    >
      {props.icon ? <FontAwesomeIcon icon={props.icon} /> : undefined}
      <Typography sx={props.sxTypo}>{props.linkText}</Typography>
    </Link>
  );
};

export default TextLink;
