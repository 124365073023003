import React from "react";
import YouTube, { YouTubeProps } from "react-youtube";

export class YoutubeOptions {
  constructor(
    public id: string,
    public height: string = "390",
    public width: string = "100%"
  ) {
    if (window.innerWidth > 1000){
        this.height = "560"
    }
  }
};

const YoutubePlayer = (props: {options: YoutubeOptions}) => {
  const onPlayerReady: YouTubeProps["onReady"] = (event) => {
    event.target.pauseVideo();
  };

  const opts: YouTubeProps["opts"] = {
    height: props.options.height,
    width: props.options.width,

    playerVars: {
      autoplay: 1,
    },
  };

  return <YouTube videoId={props.options.id} opts={opts} onReady={onPlayerReady} />;
};

export default YoutubePlayer;
