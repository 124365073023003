import {
  Card,
  CardMedia,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Artist } from "../artists";
import ArtistNameCountry from "../ArtistNameCountry/ArtistNameCountry";
import { Link } from "react-router-dom";
import "./ArtistCard.css";

const ArtistCard = (props: {
  artistData: Artist | undefined
}) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Link
        to={`/artist/${props.artistData?.id}`}
        className={"artist-card-link"}
      >
        <Card sx={{backgroundColor: "var(--kanamaja-washed-white)"}}>
          <CardMedia
            component="img"
            key={props.artistData?.id}
            sx={{ height: 200, objectFit: "cover" }}
            image={`${props.artistData?.coverImg}_cover.jpg`}
          />
          <CardContent className={"artist-card-content"} sx={{ padding: "0 0 12px 0", '&:last-child': { pb: 0 }}}>
          <ArtistNameCountry name={props.artistData?.name} country={props.artistData?.country} sx={{borderRadius: 0, padding: 0}} />            
          {(props.artistData?.style !== undefined) ? 
            <Typography
              className={"artist-card-style"}
              sx={{ mb: 0, marginTop: 0 }}
              padding={"10px 0 10px 0"}
              textAlign={"center"}
            >
              {props.artistData?.style.join(" / ")}
            </Typography>
          : undefined}
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};

export default ArtistCard;
