import { Artist } from "./artists";

export const Arts: Artist[] = [
  {
    id: "4FA9D72801784B66B30377A5E428BAFF",
    name: "Sebastián López Borda",
    coverImg: "/img/slb",
    fb: "https://www.facebook.com/sebastian.lopezborda",
    ig: "https://www.instagram.com/sebastianlopezborda/",
    web: "https://sebastianlopezborda.myportfolio.com/",
    bio: "/data/slb.txt"
  },
  {
    id: "CDCC9C3239C048FDA845C1CF00AE875A",
    name: "Natalia Buitrago Norato",
    coverImg: "/img/nbr",
    ig: "https://www.instagram.com/elcaparazon/",
    web: "https://cargocollective.com/nataliamariadelbosqueartist",
    bio: "/data/nbr.txt"
  },
  {
    id: "4DF6497B3BC0402681506C50CF3DD94A",
    name: "Angelina Potsijenkova",
    coverImg: "/img/ap",
    ig: "https://www.instagram.com/needovolen_art/",
    soundcloud: "https://soundcloud.com/paparim6996",
    web: "https://readymag.website/u2721616709/4566264/",
    bio: "/data/ap.txt"
  },
  {
    id: "08C6E57769834C82AACC5A247C72E365",
    name: "Roman Grizik",
    coverImg: "/img/grizik",
    ig: "https://www.instagram.com/romartio/",
    bio: "/data/rg.txt"
  },
  {
    id: "152EE16850FE495D948EF36EFDCBDB33",
    name: "Hannah Aleksandra",
    coverImg: "/img/ha",
    ig: "https://www.instagram.com/artdiaryofabalticgirl/",
    fb: "https://www.facebook.com/artdiaryofabalticgirl",
    bio: "/data/ha.txt"
  },
  {
    id: "838F4532CB98490CACB416C0D5B31F2B",
    name: "Ketter Raudmets",
    coverImg: "/img/kr",
    ig: "https://www.instagram.com/ketaskuus/",
    web: "https://www.ketterphotography.com/",
    bio: "/data/kr.txt"
  },
  {
    id: "46179AABEF9743329EFFD38EC31154CD",
    name: "Ave Palm",
    coverImg: "/img/palm",
    ig: "https://www.instagram.com/avepalm7/",
    web: "https://avepalm7.wixsite.com/portfolio",
    bio: "/data/palm.txt"
  },
  {
    id: "C04124A2BB8C4A8EB3A0D816876DC997",
    name: "Eva Tarn - New Life Studio",
    coverImg: "/img/et",
    fb: "https://www.facebook.com/NewLifeFashionStudio/",
    ig: "https://www.instagram.com/new_life_studio_official/",
    bio: "/data/et.txt"
  },
  {
    id: "38C92E3A05A640629704630B4F59F36F",
    name: "Paula Liis Eplik",
    coverImg: "/img/ple",
    ig: "https://www.instagram.com/jalatallaja",
    bio: "/data/ple.txt"
  },
  {
    id: "B3515ECE181548909BEFA4ACEFA6C6CA",
    name: "Kairi Matt",
    coverImg: "/img/km",
    bio: "/data/km.txt",
    ig: "https://www.instagram.com/mairikatt/"
  },
  {
    id: "8032FB97BAE443A0A40F82BD997DFADC",
    name: "Siobhan Henry",
    coverImg: "/img/SiobhanHenry",
    ig: "https://www.instagram.com/shutupsiobhan",
    bio: "/data/sh.txt"
  },
  {
    id: "29EE6F217D384A998B8E27E0E5981AB3",
    name: "Kaspar Aus",
    coverImg: "/img/kaspar",
    fb: "https://www.facebook.com/to.belonging",
    ig: "https://www.instagram.com/aus_tants/",
    web: "https://kasparaus.wixsite.com/artist",
    bio: "/data/ka.txt"
  },
  {
    id: "AFE112F50C7D44F289DABE86224F2869",
    name: "Gabriela Lesmes",
    coverImg: "/img/gl",
    web: "https://gllcradaca.com",
    ig: "https://www.instagram.com/gllcradaca/",
    bio: "/data/gl.txt"
  },
  {
    id: "4CA050C365674EFAB3F44B345B26794C",
    name: "Mark Walker",
    coverImg: "/img/mw",
    web: "https://markwalkermark.com",
    bio: "/data/mw.txt"
  },
  {
    id: "AA64EF33B7D1450AABD0A25E9D67E6E7",
    name: "Laura Smideberga",
    coverImg: "/img/ls",
    bio: "/data/ls.txt",
    ig: "https://www.instagram.com/laura_shmideberga",
    web: "https://laurashmideberga.com"
  },
  {
    id: "DFA783A10CA7487A9504A91B56A2D649",
    name: "Nathaniel Lazar",
    coverImg: "/img/nl",
    ig: "https://www.instagram.com/nathaniellakedesigns",
    web: "https://nathaniellake.design",
    bio: "/data/nl.txt"
  },
  {
    id: "6F091712E0E9478CBEABB4FE3E6CC0FC",
    name: "Egle",
    coverImg: "/img/egle",
    web: "https://eglesilko.com",
    ig: "https://www.instagram.com/eglesilkojewelry/",
    fb: "https://www.facebook.com/eglesilkojewelry/",
    bio: "/data/egle.txt"
  },
  {
    id: "CA976BBF59514C6AA957E744253B5D2F",
    name: "Little Ambassador Tattoo",
    coverImg: "/img/let",
    ig: "https://www.instagram.com/littleambassadortattoo",
    bio: "/data/let.txt"
  },
];
