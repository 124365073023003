import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { ThemeProvider } from "@emotion/react";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { MenuItems } from "../Constants";
import { KanamajaDrawerTheme } from "../theme";
import MenuItem from "../MenuItem/MenuItem";
import "./MenuDrawer.css"

export default function MenuDrawer() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{ width: 200, display: { xs: "flow", md: "none" } }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <List>
        {MenuItems.map((item, _) => (
          <Link to={item.path} className={"menu-drawer-item"}>
            <ListItem key={item.name} disablePadding>
              <MenuItem icon={item.icon} name={item.name}/>
            </ListItem>
          </Link>
        ))}
      </List>
      <Divider />
    </Box>
  );

  return (
    <ThemeProvider theme={KanamajaDrawerTheme}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        onClick={toggleDrawer(true)}
      >
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
    </ThemeProvider>
  );
}