import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SocialMedia } from "../types";
import "./SocialFollow.css";

const SocialFollow = (props: { media: SocialMedia[] }) => {
  return (
    <div>
      {props.media.map((social, _) =>
        social.link.startsWith("mailto:") ? (
          <a href={social.link} className={`social ${social.className}`}>
            <FontAwesomeIcon icon={social.icon} />
          </a>
        ) : (
          <a
            href={social.link}
            rel="noreferrer"
            target="_blank"
            className={`social ${social.className}`}
          >
            <FontAwesomeIcon icon={social.icon} />
          </a>
        )
      )}
    </div>
  );
};

export default SocialFollow;
