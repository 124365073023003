import { PropsWithChildren } from "react";
import { Box, Container } from "@mui/material";

type PageBaseProps = {
  maxWidthOverride?: string
};

const PageBase = ({ maxWidthOverride, children }: PropsWithChildren<PageBaseProps>) => {
  return (
    <Box sx={{ color: "var(--kanamaja-green-dark)" }}>
      <Container
        component="section"
        maxWidth={false}
        sx={{ backgroundColor: "var(--kanamaja-washed-white)", p: 2, maxWidth: (maxWidthOverride !== undefined ? maxWidthOverride : "md") }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default PageBase;