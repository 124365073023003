import { Typography, SxProps, Theme } from "@mui/material";

const PageTitle = (props: { title: string; sxProps?: SxProps<Theme> }) => {
  return (
    <Typography
      sx={[...(Array.isArray(props.sxProps) ? props.sxProps : [props.sxProps])]}
      variant="h4"
      color={"var(--kanamaja-red)"}
      fontWeight={"bold"}
      textAlign={"center"}
      marginBottom={2}
    >
      {props.title}
    </Typography>
  );
};

export default PageTitle;
