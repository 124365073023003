import React from "react";
import "./App.css";
import AppNavBar from "../AppNavBar/AppNavBar";
import LandingPage from "../../pages/Landing/LandingPage";
import { Route, Routes } from "react-router-dom";
import AboutUs from "../../pages/AboutUs/AboutUs";
import Lineup from "../../pages/Lineup/Lineup";
import Exhibition from "../../pages/Exhibition/Exhibition";
import History from "../../pages/History/History";
import Tickets from "../../pages/Tickets/tickets";
import Location from "../../pages/Location/Location";
import ArtistDetails from "../../pages/ArtistDetails/ArtistDetails";

export default function App() {
  return (
    <div>
      <AppNavBar />
      <Routes>
        <Route path="/" element={<LandingPage />}/>
        <Route path="/lineup" element={<Lineup />}/>
        <Route path="/artist/:artistId" element={<ArtistDetails />}/>
        <Route path="/art" element={<Exhibition />}/>
        <Route path="/history" element={<History/>}/>
        <Route path="/tickets" element={<Tickets/>}/>
        <Route path="/location" element={<Location/>}/>
        <Route path="/about" element={<AboutUs />}/>
      </Routes>
    </div>
  );
}
