import "./AppNavBar.css";
import { Link } from "react-router-dom";
import {
  ThemeProvider,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Typography
} from "@mui/material";
import { KanamajaHeaderTheme } from "../theme";
import MenuDrawer from "../MenuDrawer/MenuDrawer";
import MenuItem from "../MenuItem/MenuItem";
import {
  MenuItems,
  KanamajaFestTitle,
  KanamajaFestTitleShort,
} from "../Constants";

export default function AppNavBar() {
  return (
    <ThemeProvider theme={KanamajaHeaderTheme}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/">
            <IconButton
              size="large"
              edge="start"
              aria-label="kanamaja-logo"
              sx={{ display: { xs: "none", md: "flex" }, fill: '#f8edd5' }}
            >
              <img
                className="AppBarIcon"
                src="/img/kanamajaLogo.svg"
                alt="Kanasaur icon"
              />
            </IconButton>
          </Link>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            fontSize="2.25rem"
            fontFamily={'RetroTeam'}
          >
            {KanamajaFestTitle}
          </Typography>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {MenuItems.map((item) => (
              <Link to={item.path} className="AppBarLink">
                <MenuItem icon={item.icon} name={item.name}/>
              </Link>
            ))}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <MenuDrawer />
          </Box>
          <Link to="/">
            <IconButton
              size="large"
              edge="start"
              aria-label="kanamaja-logo"
              sx={{ display: { xs: "flex", md: "none" } }}
            >
              <img
                className="AppBarIcon"
                src="/img/kanamajaLogo.svg"
                alt="Kanasaur icon"
              />
            </IconButton>
          </Link>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            fontSize="2.25rem"
            fontFamily={'RetroTeam'}
          >
            {KanamajaFestTitleShort}
          </Typography>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
}
