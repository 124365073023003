import { createGlobalStyle } from 'styled-components'; 

const FontStyles = createGlobalStyle`
    @font-face {
        font-family: 'RetroTeam';
        src: local('RetroTeam'), local('RetroTeam'),
        url(${'./RetroTeam.woff'}) format('woff');
        url(${'./RetroTeam.woff2'}) format('woff2');
        font-weight: 300;
        font-style: normal;
    }
`;

export default FontStyles;